import { Injectable } from '@angular/core';
import { DeForm, DeFormGroup, DeTemplateForm, DeTemplateFormName, DeTemplateFormType } from 'projects/de-template-forms/src/lib/models/DeTemplateForms';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { UserType } from '../forms/Forms';

const DB_NAME = 'cd_job_brief';
const DB_VERSION = 6;

export type GenericTableName = 'crew_favorites' | 'user_settings' | 'hospital_favorites';
export const CREW_FAVORITES_GENERIC_TABLE_NAME: GenericTableName = 'crew_favorites';
export const USER_SETTINGS_GENERIC_TABLE_NAME: GenericTableName = 'user_settings';
export const HOSPITAL_FAVORITES_GENERIC_TABLE_NAME: GenericTableName = 'hospital_favorites';

export interface DeDatabaseStore {
  name: string;
  keypath: string;
}

type TemplateStoreType =
  'pre' | 'post' // Line pre/post templates (preserving initial name)
  | 'fleet_pre' | 'fleet_post'
  | 'non-craft_pre' | 'non-craft_post'
  | 'transmission_pre' | 'transmission_post';

/**
 * $_FORM_TYPE_ADJUSTMENT_$ -> Returns the prefix for the object store containing the specific template type
 *
 * @param template_type Template type ('line', 'fleet')
 * @param template_name Template name ('Pre-Job Brief', 'Post-Job Brief')
 */
const formTemplateStorePrefix = (template_type: DeTemplateFormType, template_name: DeTemplateFormName) =>
  template_name === 'Pre-Job Brief'
    ? template_type === 'line'
      ? 'pre'
      : template_type === 'fleet'
        ? 'fleet_pre'
        : template_type === 'non-craft'
          ? 'non-craft_pre'
          : template_type === 'transmission'
            ? 'transmission_pre'
            : undefined
    : template_type === 'line'
      ? 'post'
      : template_type === 'fleet'
        ? 'fleet_post'
        : template_type === 'non-craft'
          ? 'non-craft_post'
          : template_type === 'transmission'
            ? 'transmission_post'
            : undefined;

const databaseStores: DeDatabaseStore[] = [
  { name: 'forms', keypath: 'uuid' },
  // Line pre/post templates (preserving initial name)
  { name: 'pre_templates', keypath: 'version' },
  { name: 'post_templates', keypath: 'version' },
  { name: 'fleet_pre_templates', keypath: 'version' },
  { name: 'fleet_post_templates', keypath: 'version' },
  { name: 'non-craft_pre_templates', keypath: 'version' },
  { name: 'non-craft_post_templates', keypath: 'version' },
  { name: 'transmission_pre_templates', keypath: 'version' },
  { name: 'transmission_post_templates', keypath: 'version' },
  { name: 'generic', keypath: 'table' }
];

@Injectable({
  providedIn: 'root'
})
export class LocalDatabaseService {

  db$ = new BehaviorSubject<IDBDatabase>(null);

  constructor() { }

  async initialize() {
    return new Promise<void>((resolve, reject) => {
      let request = window.indexedDB.open(DB_NAME, DB_VERSION);

      const db$ = this.db$;

      request.onsuccess = event => {
        // Get DB from event
        db$.next((event.target as any).result as IDBDatabase);
        resolve();
      };

      request.onerror = event => {
        console.error('Failed to initialize IndexedDB.');
        console.error(request.error);
        reject();
      };

      request.onupgradeneeded = async event => {
        const db = (event.target as any).result as IDBDatabase;
        databaseStores.forEach(store => {
          if (!db.objectStoreNames.contains(store.name)) {
            db.createObjectStore(store.name, { keyPath: store.keypath });
          }
        });
        // Resolve promise in request.onsuccess callback
      };
    });
  }

  private _newTransaction(store: string) {
    // Create transaction from database
    const transaction = this.db$.value.transaction(store, 'readwrite');

    transaction.oncomplete = event => { };

    transaction.onerror = event => {
      console.error('Database transaction failed.');
    }

    transaction.onabort = event => {
      console.error('Database transaction aborted.');
    }

    return transaction;
  }

  private _formsStore() {
    return this._newTransaction('forms').objectStore('forms');
  }

  private _templatesStore(type: TemplateStoreType) {
    return this._newTransaction(`${type}_templates`).objectStore(`${type}_templates`);
  }

  private _genericStore() {
    return this._newTransaction('generic').objectStore('generic');
  }

  postForm(form: DeForm) {
    if (!form) {
      console.error('No value provided for form.');
      return;
    }

    if (!this.db$.value) {
      console.error('No local database instance. Offline storage disabled.');
      return;
    }

    if (form.form_metadata.form_uuid) {
      return this.updateForm(form);
    }

    form.form_metadata.form_uuid = uuidv4();

    return new Promise((resolve, reject) => {
      const operation = this._formsStore().add({ ...form, uuid: form.form_metadata.form_uuid, form_template: undefined });

      operation.onsuccess = event => {
        // console.log('Successfully added form to store.');
        resolve((event.target as IDBRequest).result);
      }

      operation.onerror = event => {
        console.error('Error adding form to the store');
        reject(event);
      }
    });
  }

  updateForm(form: DeForm) {
    if (!form) {
      console.error('No value provided for form.');
      return;
    }

    if (!this.db$.value) {
      console.error('No local database instance. Offline storage disabled.');
      return;
    }

    if (!(form.form_metadata.form_uuid?.length > 0)) {
      return this.postForm(form);
    }

    return new Promise((resolve, reject) => {
      const operation = this._formsStore().put({ ...form, uuid: form.form_metadata.form_uuid, form_template: undefined });

      operation.onsuccess = event => {
        // console.log('Successfully updated form in store.');
        // console.log(event);
        resolve((event.target as IDBRequest).result);
      }

      operation.onerror = event => {
        console.error('Error updating form in the store');
        reject(event);
      }
    });
  }

  deleteForm(form_uuid: string) {
    if (!this.db$.value) {
      console.error('No local database instance. Offline storage disabled.');
      return;
    }

    return new Promise((resolve, reject) => {
      const operation = this._formsStore().delete(form_uuid);

      operation.onsuccess = event => {
        resolve(event.target as IDBRequest);
      }

      operation.onerror = event => {
        reject(event);
      }
    });
  }

  getAllForms(user_type: UserType, form_group_uuid?: string): Promise<DeForm[]> {
    if (!this.db$.value) {
      console.error('No local database instance. Offline storage disabled.');
      return;
    }

    return new Promise((resolve, reject) => {
      // Get all data in formsStore
      const operation = this._formsStore().getAll();

      operation.onsuccess = event => {
        const forms = form_group_uuid?.length > 0
          ? ((event.target as IDBRequest).result as any[])?.filter?.((f: DeForm) => f.form_metadata?.form_group_uuid === form_group_uuid)
          : (event.target as IDBRequest).result as any[];

        // $_FORM_TYPE_ADJUSTMENT_$ -> Some original line forms did not have a user type set
        resolve(forms?.filter?.((f: DeForm) => f.form_metadata.user_type === user_type || (!f.form_metadata.user_type && user_type === 'line')));
      }

      operation.onerror = event => {
        reject(event);
      }
    });
  }

  numberOfForms(): Promise<number> {
    if (!this.db$.value) {
      console.error('No local database instance. Offline storage disabled.');
      return;
    }

    return new Promise((resolve, reject) => {
      // Get all data in formsStore
      const operation = this._formsStore().count();

      operation.onsuccess = event => {
        resolve((event.target as IDBRequest).result as number);
      }

      operation.onerror = event => {
        reject(event);
      }
    });
  }

  getFormByUUID(form_uuid: string): Promise<DeForm> {
    if (!this.db$.value) {
      console.error('No local database instance. Offline storage disabled.');
      return;
    }

    return new Promise((resolve, reject) => {
      // Get all data in formsStore
      const operation = this._formsStore().get(form_uuid);

      operation.onsuccess = event => {
        const form = (event.target as IDBRequest).result;
        resolve(form);
      }

      operation.onerror = event => {
        reject(event);
      }
    });
  }

  async updateFormGroup(form_group: DeFormGroup) {
    form_group.form_group_uuid = form_group.form_group_uuid ?? uuidv4();
    form_group.forms.forEach(f => f.form_metadata.form_group_uuid = form_group.form_group_uuid);
    await Promise.all(form_group.forms.map(async f => await this.updateForm(f)));
  }

  async getFormGroupByUUID(user_type: UserType, form_group_uuid: string): Promise<DeFormGroup> {
    const forms: DeForm[] = (await this.getAllForms(user_type).catch(e => []))
      ?.filter((form: DeForm) => form.form_metadata?.form_group_uuid === form_group_uuid)
      .filter(
        (form: DeForm, _, forms: DeForm[]) =>
          // Don't include the form if there is already a form in the group with the same display_name (e.g. '3rd Pre-Job Brief')
          // whose completed timestamp is more recent (this only applies if both are completed)
          !(forms.some((f: DeForm) => f.form_metadata.form_uuid !== form.form_metadata.form_uuid
            && f.form_metadata.display_name === form.form_metadata.display_name
            && f.form_metadata.completed_timestamp
            && form.form_metadata.completed_timestamp
            && f.form_metadata.completed_timestamp > form.form_metadata.completed_timestamp))
      )
      .sort((f1: DeForm, f2: DeForm) => f1.form_metadata?.form_id - f2.form_metadata?.form_id);

    return forms?.length > 0
      ? {
        form_group_uuid,
        completed: !forms.some(f => !(f.form_metadata?.completed_timestamp)),
        forms,
        user_type
      }
      : undefined;
  }

  /**
   * Post template to db
   *
   * @param template DeTemplateForm
   */
  async postTemplate(template: DeTemplateForm) {
    if (!template) {
      console.error('No value provided for template.');
      return;
    }

    if (!this.db$.value) {
      console.error('No local database instance. Offline storage disabled.');
      return;
    }

    if ((await this.getTemplate(template.formtype, template.formname, template.version).catch(e => undefined))?.version > -1) {
      console.log('Template version already exists locally, attempting to replace with new template...');
      if (!(await this.deleteTemplate(template.formtype, template.formname, template.version).catch(e => undefined))) {
        console.error('Error replacing existing template, using old template copy.');
        return;
      }
    }

    return new Promise((resolve, reject) => {
      const operation = this._templatesStore(formTemplateStorePrefix(template.formtype, template.formname)).add(template);

      operation.onsuccess = event => {
        resolve((event.target as IDBRequest).result);
      }

      operation.onerror = event => {
        console.error('Error adding template to the store');
        reject(event);
      }
    });
  }

  /**
   * Get specific template from db
   *
   * @param type Pre or Post template
   * @param version (Optional) Template version, defaults to most recent version
   */
  async getTemplate(template_type: DeTemplateFormType, template_name: DeTemplateFormName, version?: number): Promise<DeTemplateForm> {
    if (!this.db$.value) {
      console.error('No local database instance. Offline storage disabled.');
      return;
    }

    return new Promise((resolve, reject) => {
      const operation = version > -1
        ? this._templatesStore(formTemplateStorePrefix(template_type, template_name)).get(version)
        : this._templatesStore(formTemplateStorePrefix(template_type, template_name)).getAll();

      operation.onsuccess = event => {
        const result = (event.target as IDBRequest).result;
        resolve(
          version > -1
            ? result
            // If no version was provided, find the template with the highest (most recent) version
            : (result ?? []).reduce((max, temp) => temp.version > max.version ? temp : max, result?.[0])
        );
      }

      operation.onerror = event => {
        reject(event);
      }
    });
  }

  /**
   * Removes specific template from db
   *
   * @param type Pre or Post template
   * @param version Template version to remove
   */
  async deleteTemplate(template_type: DeTemplateFormType, template_name: DeTemplateFormName, version: number) {
    if (!this.db$.value) {
      console.error('No local database instance. Offline storage disabled.');
      return;
    }

    return new Promise((resolve, reject) => {
      const operation = this._templatesStore(formTemplateStorePrefix(template_type, template_name)).delete(version);

      operation.onsuccess = event => {
        resolve(event.target as IDBRequest);
      }

      operation.onerror = event => {
        reject(event);
      }
    });
  }

  /**
   * Post object to one of the tables within the generic IndexedDB store
   *
   * @param table Table name
   * @param obj Object
   */
  async postObjectToGenericTable(table: GenericTableName, obj: any) {
    if (!(table?.length > 0)) {
      console.error('No value provided for table name.');
      return;
    }

    if (!this.db$.value) {
      console.error('No local database instance. Offline storage disabled.');
      return;
    }

    const existingTableValue = await this.getGenericTableValue(table);

    return new Promise((resolve, reject) => {
      const genericTable = {
        table,
        value: obj
      };

      const operation = existingTableValue ? this._genericStore().put(genericTable) : this._genericStore().add(genericTable);

      operation.onsuccess = event => {
        resolve((event.target as IDBRequest).result);
      }

      operation.onerror = event => {
        console.error('Error adding obj to the generic store');
        reject(event);
      }
    });
  }

  /**
   * Get JSON from one of the tables within the generic IndexedDB store
   *
   * @param table Table name
   */
  async getGenericTableValue<T>(table: GenericTableName): Promise<T> {
    if (!(table?.length > 0)) {
      console.error('No value provided for table name.');
      return;
    }

    if (!this.db$.value) {
      console.error('No local database instance. Offline storage disabled.');
      return;
    }

    return new Promise((resolve, reject) => {
      const operation = this._genericStore().get(table);

      operation.onsuccess = event => {
        const tableObj = (event.target as IDBRequest).result;
        resolve(tableObj?.value);
      }

      operation.onerror = event => {
        reject(event);
      }
    });
  }
}
