<div class="header">
  <button *ngIf="subMenu" class="back-button" (click)="closeSubMenu(subMenu)">
    <svg width="7px" height="12px" viewBox="0 0 7 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs></defs>
      <g id="arrows/chevron-left-xxsmall" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M0.757410154,12.064139 C0.579945069,12.064139 0.401767793,11.9965456 0.265804029,11.8602581 C-0.00573503269,11.5887191 -0.00573503269,11.1492971 0.265804029,10.8777581 L5.07969823,6.0641876 L0.265804029,1.25029339 C-0.00573503269,0.978754333 -0.00573503269,0.538943927 0.265804029,0.267793333 C0.53734309,-0.00374572788 0.977153496,-0.00374572788 1.24862781,0.267793333 L6.55341595,5.57258147 C6.82495501,5.84412053 6.82495501,6.28393094 6.55341595,6.55508153 L1.24862781,11.8601934 C1.11311726,11.9961572 0.935263707,12.064139 0.757410154,12.064139 Z"
          id="Chevron" fill="#FFFFFF" fill-rule="nonzero"
          transform="translate(3.409610, 6.064139) rotate(-180.000000) translate(-3.409610, -6.064139) "></path>
      </g>
    </svg>
    <span>Back</span>
  </button>
  <button #closeButton class="text-button" (click)="close()">Close</button>
</div>

<ng-container [ngSwitch]="subMenu">
  <!-- Preferences Sub-Menu -->
  <ng-container *ngSwitchCase="'settings'">
    <h3>Preferences</h3>
    <div class="nav-actions">
      <a routerLink="/crew-favorites" (click)="linkClicked('Crew Favorites')">{{favoritesText}}</a>    
      <button (click)="promptUserType()">Change User Type</button>
      <a routerLink="/hospital-favorites" (click)="linkClicked('Hospital Favorites')">Hospital Favorites</a>
    </div>
  </ng-container>

  <!-- PDF Links Sub-Menu -->
  <ng-container *ngSwitchCase="'pdfs'">
    <h3>Printable PDFs</h3>
    <div class="nav-actions">
      <a *ngFor="let pdf of pdfs" href="{{pdf.url}}" target="_blank"
        (click)="linkClicked(pdf.display)">{{pdf.display}}</a>
    </div>
  </ng-container>

  <!-- Resource Links Sub-Menu -->
  <ng-container *ngSwitchCase="'links'">
    <h3>Resource Links</h3>
    <div class="nav-actions">
      <button *ngIf="data.userType === 'transmission'" (click)="openSubMenu('pdfs')" class="nav-actions-menu">
        <span>Printable PDFs</span>
        <svg width="7px" height="12px" viewBox="0 0 7 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs></defs>
          <g id="arrows/chevron-right-xxsmall" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M0.757410154,12.064139 C0.579945069,12.064139 0.401767793,11.9965456 0.265804029,11.8602581 C-0.00573503269,11.5887191 -0.00573503269,11.1492971 0.265804029,10.8777581 L5.07969823,6.0641876 L0.265804029,1.25029339 C-0.00573503269,0.978754333 -0.00573503269,0.538943927 0.265804029,0.267793333 C0.53734309,-0.00374572788 0.977153496,-0.00374572788 1.24862781,0.267793333 L6.55341595,5.57258147 C6.82495501,5.84412053 6.82495501,6.28393094 6.55341595,6.55508153 L1.24862781,11.8601934 C1.11311726,11.9961572 0.935263707,12.064139 0.757410154,12.064139 Z"
              id="Chevron" fill="#067799" fill-rule="nonzero"></path>
          </g>
        </svg>
      </button>
      <a *ngFor="let link of links" href="{{link.url}}" target="_blank"
        (click)="linkClicked(link.display)">{{link.display}}</a>
    </div>
  </ng-container>

  <!-- Main Menu -->
  <div *ngSwitchDefault class="nav-actions">
    <button (click)="openSubMenu('settings')" class="nav-actions-menu">
      <span>Preferences</span>
      <svg width="7px" height="12px" viewBox="0 0 7 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs></defs>
        <g id="arrows/chevron-right-xxsmall" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path
            d="M0.757410154,12.064139 C0.579945069,12.064139 0.401767793,11.9965456 0.265804029,11.8602581 C-0.00573503269,11.5887191 -0.00573503269,11.1492971 0.265804029,10.8777581 L5.07969823,6.0641876 L0.265804029,1.25029339 C-0.00573503269,0.978754333 -0.00573503269,0.538943927 0.265804029,0.267793333 C0.53734309,-0.00374572788 0.977153496,-0.00374572788 1.24862781,0.267793333 L6.55341595,5.57258147 C6.82495501,5.84412053 6.82495501,6.28393094 6.55341595,6.55508153 L1.24862781,11.8601934 C1.11311726,11.9961572 0.935263707,12.064139 0.757410154,12.064139 Z"
            id="Chevron" fill="#067799" fill-rule="nonzero"></path>
        </g>
      </svg>
    </button>
    <button *ngIf="links?.length > 0" (click)="openSubMenu('links')" class="nav-actions-menu">
      <span>Resource Links</span>
      <svg width="7px" height="12px" viewBox="0 0 7 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs></defs>
        <g id="arrows/chevron-right-xxsmall" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path
            d="M0.757410154,12.064139 C0.579945069,12.064139 0.401767793,11.9965456 0.265804029,11.8602581 C-0.00573503269,11.5887191 -0.00573503269,11.1492971 0.265804029,10.8777581 L5.07969823,6.0641876 L0.265804029,1.25029339 C-0.00573503269,0.978754333 -0.00573503269,0.538943927 0.265804029,0.267793333 C0.53734309,-0.00374572788 0.977153496,-0.00374572788 1.24862781,0.267793333 L6.55341595,5.57258147 C6.82495501,5.84412053 6.82495501,6.28393094 6.55341595,6.55508153 L1.24862781,11.8601934 C1.11311726,11.9961572 0.935263707,12.064139 0.757410154,12.064139 Z"
            id="Chevron" fill="#067799" fill-rule="nonzero"></path>
        </g>
      </svg>
    </button>
    <a routerLink="/contact-us" (click)="linkClicked('Contact Us')">Contact Us</a>
    <a *ngIf="blankJobBriefUrl?.length > 0" href="{{blankJobBriefUrl}}" (click)="linkClicked('View Blank Job Brief')">
      Printable Blank Job Brief
    </a>
    <button (click)="logout()">Logout</button>
    <p>Version {{version}}</p>
  </div>
</ng-container>