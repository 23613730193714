import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'forms',
    canActivate: [MsalGuard],
    loadChildren: () => import('./modules/forms/forms.module').then(m => m.FormsModule)
  },
  {
    path: 'search',
    canActivate: [MsalGuard],
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'crew-favorites',
    canActivate: [MsalGuard],
    loadChildren: () => import('./modules/crew-favorites/crew-favorites.module').then(m => m.CrewFavoritesModule)
  },
  {
    path: 'hospital-favorites',
    canActivate: [MsalGuard],
    loadChildren: () => import('./modules/hospital-favorites/hospital-favorites.module').then(m => m.HospitalFavoritesModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'forms'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
